import checkout from "./form";

let initialValues = {};

Object.keys(checkout.formField).forEach(function (key, index) {
  Object.keys(checkout.formField[key]).forEach(function (question, index) {
    initialValues = { ...initialValues, [checkout.formField[key][question].name]: "" };
  });
});
export default initialValues;
