import styled from "styled-components";

export const AgentlessContainer = styled.div`
padding: 20px;
`
export const FormWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
justify-items: center;
align-items: center;
`

export const FormContainer = styled.div`
margin-top: 30px;
margin-bottom: 30px;
width: 80%;
background: #fff;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: 10px;
padding: 30px;
box-shadow: 0 1px 3px rgba(0,0,0,0.2);
transition: all 0.2s ease-in-out;
`

export const LoadingContainer = styled.div`
margin: auto;
margin-top: 50px;
display: flex;
flex-direction: column;
align-items: center;
width: 80%;

`