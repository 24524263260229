import React from 'react'
import Form from '../Form';

const Recovery = (props) => {
    const { formField, values, errors, touched } = props.formData;
    const data = formField.recovery;
  return (
    <Form
      data={data}
      errors={errors}
      values={values}
      touched={touched}
      subtitle="what are your recovery requirements"
      title="recovery"
    />
  )
}

export default Recovery