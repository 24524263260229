import { useLocation, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useContext } from 'react';
import AuthContext from '../../context/auth-context';

export function RequireAuth(props) {
  const ctx = useContext(AuthContext);
  const navigate = useNavigate();
  Auth.currentAuthenticatedUser().then(user => {
  }).catch(error => {
    navigate("/Login");
  })
  return props.children;
}