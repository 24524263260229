import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import image from "../../assets/images/DR_Icon_Black.png";
import { ImgWrapper } from "./SideBarElements";
import Typography from "../ui/typography";
import { menuData } from "./menuData";
import { Link } from "react-router-dom";
import { Collapse } from "@mui/material";
import { ExpandLess, ExpandMore, StarBorder } from "@mui/icons-material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const drawerWidth = 230;

const openedMixin = (theme) => ({
  backgroundColor: "transparent",
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  backgroundColor: "transparent",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  backgroundColor: "transparent",
  paddingLeft: "25%",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  backgroundColor: "transparent",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SideBar = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <Box
      onMouseEnter={props.onMenuHover}
      onMouseLeave={props.onMenuLeave}
      //onClick={props.onMenuClick}
      sx={{ display: "flex", marginTop: "10px" }}
    >
      <Drawer
        variant="permanent"
        open={props.menuClick}
        sx={{ backgroundColor: "transparent" }}
      >
        <DrawerHeader sx={{ display: "flex", justifyContent: "left" }}>
          <ImgWrapper>
            <img src={image} alt="picture" width="30px" height="30px" />
          </ImgWrapper>
          <Typography
            sx={{
              marginLeft: "8%",
              paddingTop: "6px",
              display: props.menuClick ? "revert" : "none",
            }}
          >
            Disraptor
          </Typography>
        </DrawerHeader>
        <Divider />
        <List>
          {menuData.map((item, index) => {
            if (!item.dropdown) {
              return (
                <Link to={item.link} key={index}>
                  <ListItem
                    key={item.text}
                    disablePadding
                    sx={{ display: "block", borderRadius: "0.5rem" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: props.menuClick ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={[
                          {
                            minWidth: 0,
                            mr: props.menuClick ? 3 : "auto",
                            justifyContent: "center",
                            backgroundColor: "rgb(255,255,255)",
                            boxShadow:
                              "rgb(20 20 20 / 12%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(20 20 20 / 7%) 0rem 0.125rem 0.25rem -0.0625rem",
                            borderRadius: "0.5rem",
                            padding: "0.5rem",
                          },
                          {
                            "&:hover": {
                              color: "rgb(4,153,242)",
                            },
                          },
                        ]}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        secondary={item.text}
                        sx={{
                          opacity: props.menuClick ? 1 : 0,
                          fontWeight: "500",
                          fontSize: "0.875rem",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                </Link>
              );
            } else {
              return (
                <div key={index}>
                  <ListItemButton onClick={handleClick}>
                    <ListItemIcon
                      sx={[
                        {
                          minWidth: 0,
                          mr: props.menuClick ? 3 : "auto",
                          justifyContent: "center",
                          backgroundColor: "rgb(255,255,255)",
                          boxShadow:
                            "rgb(20 20 20 / 12%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(20 20 20 / 7%) 0rem 0.125rem 0.25rem -0.0625rem",
                          borderRadius: "0.5rem",
                          padding: "0.5rem",
                        },
                        {
                          "&:hover": {
                            color: "rgb(4,153,242)",
                          },
                        },
                      ]}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      secondary={item.text}
                      sx={{
                        opacity: props.menuClick ? 1 : 0,
                        fontWeight: "500",
                        fontSize: "0.875rem",
                      }}
                    />
                    {open ? (
                      <ExpandLess sx={{ opacity: props.menuClick ? 1 : 0 }} />
                    ) : (
                      <ExpandMore sx={{ opacity: props.menuClick ? 1 : 0 }} />
                    )}
                  </ListItemButton>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.sublist.map((subitem, subindex) => {
                         
                          return (
                            <Link key={subindex} to={subitem.link}>
                              <ListItemButton sx={{ pl: 4 }}>
                                <ListItemIcon
                                  sx={{
                                    fontSize: "60%",
                                    opacity: props.menuClick ? 1 : 0,
                                  }}
                                >
                                  <FiberManualRecordIcon />
                                </ListItemIcon>
                                <ListItemText
                                  secondary={subitem.name}
                                  sx={[
                                    {
                                      opacity: props.menuClick ? 1 : 0,
                                      fontWeight: "500",
                                      fontSize: "0.875rem",
                                    },
                                    {
                                      "&:active": {
                                        color: "rgb(4,153,242)",
                                      },
                                    },
                                  ]}
                                />
                              </ListItemButton>
                            </Link>
                          );
                        
                      })}
                    </List>
                  </Collapse>
                </div>
              );
            }
          })}
        </List>
        <Divider />
      </Drawer>
    </Box>
  );
};

export default SideBar;
