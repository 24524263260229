import React from "react";
import Form from "../Form";

const AdditionalInfo = (props) => {
  const { formField, values, errors, touched } = props.formData;
  const data = formField.additionalInfoData;
  return (
    <Form
      data={data}
      errors={errors}
      values={values}
      touched={touched}
      subtitle="Tell us about your company"
      title="About You"
    />
  );
};

export default AdditionalInfo;
