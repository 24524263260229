export const cardData = [
  {
    icon: require("../../assets/images/agentless.svg").default,
    header: "Migrate",
    subtitle: "Gather data automatically by installing an agent",
    link: "/Migration",
  },
  {
    icon: require("../../assets/images/agent.svg").default,
    header: "Modernize",
    subtitle: "Answer a set of questions to give an idea of your systems",
    link: "/Modernization",
  },
];
