import {QuestionsAPI} from '../../../../api/questions/QuestionsAPI';
import { additionalInfoData } from "../additionalInfo/data/additionalInfoData";

let result = await QuestionsAPI.get();
let form = {
  formId: "migration-form",
  formField: {
    additionalInfoData,
  },
};

Object.keys(result).map((item) => {
  form.formField[item] = result[item];
})
export default form;
