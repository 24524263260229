import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Button from "../../components/ui/button";
import Typography from "@mui/material/Typography";
import { AgentlessContainer, LoadingContainer } from "./AgentlessElements";
import Discovery from "./form/discovery/Discovery";
import Security from "./form/security/Security";
import Recovery from "./form/recovery/Recovery";
import Refactoring from "./form/refactoring/Refactoring";
import AdditionalInfo from "./form/additionalInfo/AdditionalInfo";
import Performance from "./form/performance/Performance";
import { Formik, Form } from "formik";
import form from "./form/schemas/form";
import { Card, Grid } from "@mui/material";
import initialValues from "./form/schemas/initialValues";
import validations from "./form/schemas/Validations";
import { AnswersAPI } from "../../api/answers/AnswersAPI";
import SnackBar from "../../components/ui/SnackBar/SnackBar";

const steps = [
  "Company Info",
  "Discovery of Infrastructure",
  "Performance",
  "Security and Compliance",
  "Disaster Recovery",
  "Refactoring",
];

const Agentless = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [index, setIndex] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [loading, setLoading] = useState(false);

  const { formId, formField } = form;
  const currentValidation = validations[activeStep];

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = async (values, actions) => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep === steps.length - 1) {
      setIsSubmitting(true);
      await AnswersAPI.post(values);
      setIsSubmitting(false);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    setIndex(index + 1);
    actions.setTouched({});
    actions.setSubmitting(false);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setIndex(index - 1);
  };

  function getStepContent(stepIndex, formData) {
    switch (stepIndex) {
      case 0:
        return <AdditionalInfo formData={formData} />;
      case 1:
        return <Discovery formData={formData} />;
      case 2:
        return <Performance formData={formData} />;
      case 3:
        return <Security formData={formData} />;
      case 4:
        return <Recovery formData={formData} />;
      case 5:
        return <Refactoring formData={formData} />;
      default:
        return null;
    }
  }

  return (
    <>
      {loading && (
        <LoadingContainer>
          <Stack spacing={1}>
            {/* For variant="text", adjust the height via font-size */}
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6}>
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                <Skeleton variant="rectangular" width={410} height={60} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Skeleton variant="rectangular" width={410} height={60} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Skeleton variant="rectangular" width={410} height={60} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Skeleton variant="rectangular" width={410} height={60} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Skeleton variant="rectangular" width={410} height={60} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Skeleton variant="rectangular" width={410} height={60} />
              </Grid>
            </Grid>
          </Stack>
        </LoadingContainer>
      )}
      {!loading && (
        <AgentlessContainer>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    Your form has been completed successfully and we'll contact
                    you as soon as possible
                  </Typography>
                </Box>

                {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box> */}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Formik
                  initialValues={initialValues}
                  validationSchema={currentValidation}
                  onSubmit={handleNext}
                >
                  {({ values, errors, touched }) => (
                    <Form id={formId} autoComplete="off">
                      <Card
                        sx={{
                          height: "100%",
                          marginLeft: "60px",
                          marginRight: "60px",
                        }}
                      >
                        <Box p={2}>
                          {getStepContent(activeStep, {
                            values,
                            touched,
                            formField,
                            errors,
                          })}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              pt: 2,
                            }}
                          >
                            <Button
                              color="dark"
                              disabled={activeStep === 0}
                              onClick={handleBack}
                              sx={{ mr: 1 }}
                            >
                              Back
                            </Button>
                            <Box sx={{ flex: "1 1 auto" }} />
                            <Button
                              //onClick={handleNext}
                              type="submit"
                              variant="gradient"
                              color="disraptor"
                              disabled={isSubmitting}
                            >
                              {activeStep === steps.length - 1
                                ? isSubmitting
                                  ? "Submitting..."
                                  : "Submit"
                                : "Next"}
                            </Button>
                          </Box>
                        </Box>
                      </Card>
                    </Form>
                  )}
                </Formik>
              </React.Fragment>
            )}
          </Box>
        </AgentlessContainer>
      )}

      <SnackBar status={openSnack} />
    </>
  );
};

export default Agentless;
