import { api } from "../configs/axiosConfigs"
import { defineCancelApiObject } from "../configs/axiosUtils"

export const QuestionsAPI = {
  get: async function (cancel = false) {
    const response = await api.request({
      url: `/getQuestions`,
      method: "GET",
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    })
    return response.data
  },
}

// defining the cancel API object for QuestionsAPI
const cancelApiObject = defineCancelApiObject(QuestionsAPI)