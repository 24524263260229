import React from 'react'
import {Box} from "@mui/material"
import { ErrorMessage, Field } from "formik";
import Typography from '../../../components/ui/typography';
import Input from '../../../components/ui/input';

const FormField = ({ label, name, ...rest }) => {
  return (
    <Box mb={1.5}>
      <Box mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <Typography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {label}
        </Typography>
      </Box>
      <Field {...rest} name={name} as={Input} />
      <Box mt={0.75}>
        <Typography component="div" variant="caption" color="error">
          <ErrorMessage name={name} />
        </Typography>
      </Box>
    </Box>
  )
}

export default FormField