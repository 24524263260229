import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import {
  NavbarWrapper,
  CustomizedAppBar,
  NavLogo,
  CustomIconButton,
} from "./NavbarElements";
import PersonIcon from "@mui/icons-material/Person";
import Menu from "@mui/material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import PersonAdd from "@mui/icons-material/PersonAdd";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import Logout from "@mui/icons-material/Logout";
import Tooltip from "@mui/material/Tooltip";
import { Settings } from "@mui/icons-material";
import { NavLink } from "./NavbarElements";
import { Auth } from "aws-amplify";
import AuthContext from "../../context/auth-context";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Breadcrumbs from "../../components/ui/breadcrumbs"

const Navbar = (props) => {
  const [scrollnav, setscrollnav] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const ctx = useContext(AuthContext);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeNav = () => {
    if (window.scrollY > 30) {
      setscrollnav(true);
    } else {
      setscrollnav(false);
    }
  };

  const handleSignOut = () => {
    Auth.signOut();
    ctx.onLogOut();
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  return (
    <React.Fragment>
      <NavbarWrapper>
        <Box sx={{ flexGrow: 1 }}>
          <CustomizedAppBar
            scrollnav={scrollnav}
            sx={{ color: "#1976d2", borderRadius: "10px" }}
          >
            <Toolbar
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{display: "flex", flexDirection: "row", justifyContent: "flex-start", flexGrow: "1"}}>
              {/* <Breadcrumbs/> */}
              <CustomIconButton
                onClick={props.onMenuClick}
                size="large"
                edge="start"
                aria-label="menu"
                sx={{ mr: 2, ml: 4 }}
                scrollnav={scrollnav}
              >
                {!props.menuClick && <MenuIcon />}
                {props.menuClick && <MenuOpenIcon />}
              </CustomIconButton>
              </Box>
              <Tooltip title="Account settings" sx={{alignSelf: "flex-end"}}>
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <PersonIcon />
                </IconButton>
              </Tooltip>
            </Toolbar>
          </CustomizedAppBar>
        </Box>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          disableScrollLock={true}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem>
            <Avatar /> Profile
          </MenuItem>
          <MenuItem>
            <Avatar /> My account
          </MenuItem>
          <Divider />
          <NavLink to="/Signup">
          <MenuItem>
            <ListItemIcon>
              <PersonAdd fontSize="small" />
            </ListItemIcon>
            Add another account
          </MenuItem>
          </NavLink>
          <NavLink to="Settings">
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
          </NavLink>
          <NavLink to="/Login" onClick={handleSignOut}>
            <MenuItem>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </NavLink>
        </Menu>
      </NavbarWrapper>
    </React.Fragment>
  );
};

export default Navbar;
