import styled from 'styled-components'

export const AdminContainer = styled.div`
display: flex;
flex-direction: row;

`

export const SidebarContainer = styled.div`
max-width: 100%;
`

export const BodyContainer = styled.div`
margin-left: 40px;
margin-right: 40px;
margin-top: 10px;
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-items: center;
justify-content: center;
`