import { api } from "../configs/axiosConfigs"
import { defineCancelApiObject } from "../configs/axiosUtils"

export const AnswersAPI = {
  post: async function (payload, cancel = false) {
    const response = await api.request({
      url: `/createAnswers`,
      method: "POST",
      data: payload,
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    })
    return response.data
  },
}

// defining the cancel API object for AnswersAPI
const cancelApiObject = defineCancelApiObject(AnswersAPI)