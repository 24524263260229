import styled from "styled-components";
import { AppBar, IconButton, Typography } from "@mui/material";
import { styled as style } from "@mui/material/styles";
import { Link as LinkR } from "react-router-dom";

export const CustomTypography = styled(Typography)``;
export const CustomizedAppBar = style(AppBar)`
 position: static;
 border-radius: '10px';

 background:  ${({ scrollnav }) =>
   scrollnav
     ? "linear-gradient(to right,rgb(8, 212, 244), rgb(10, 241, 194))"
     : "transparent"};
 box-shadow: ${({ scrollnav }) =>
   scrollnav
     ? "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)"
     : "none"};
`;

export const NavbarWrapper = styled.div`
  height: 80px;

  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 10;
  position: sticky;
  margin: auto;
  padding: 10px;
  max-width: 97%;
  width: 97%;
`;

export const NavLink = styled(LinkR)`
  text-decoration: none;
  display: block;
  color: #000;
`;

export const NavLogo = styled(LinkR)`
  text-decoration: none;
  color: ${({ scrollnav }) => (scrollnav ? "#fff" : "#000")};
`;

export const CustomIconButton = styled(IconButton)`
  color: ${({ scrollnav }) => (scrollnav ? "#fff" : "inherit")};
`;
