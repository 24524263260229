import { Box, Card, ListItemButton } from "@mui/material";
import React, { useState } from "react";
import Input from "../../../../components/ui/input";
import { Edit, Delete } from "@mui/icons-material";

const EditInput = ({ question }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [text, setText] = useState(question.label);
  const handleEditToggle = () => {
    setIsEdit(!isEdit);
  };

  const handleTextEdit = (event) => {
        setText(event.target.value);
  }
  return (
    <Box mb={1.5}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
        }}
        mb={1}
        lineHeight={0}
        display="inline-block"
      >
        <Input onChange={handleTextEdit} disabled={!isEdit} value={text}></Input>
        <Card
          sx={{
            height: "100%",
            marginLeft: "12px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ListItemButton onClick={handleEditToggle}>
            <Edit fontSize="small" color="secondary" />
          </ListItemButton>
        </Card>
        <Card
          sx={{
            height: "100%",
            marginLeft: "12px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ListItemButton>
            <Delete fontSize="small" color="error" />
          </ListItemButton>
        </Card>
      </Box>
    </Box>
  );
};

export default EditInput;
