import React from 'react'
import { migrationData } from '../../components/data/migrationData';
import Card from '../../components/ui/card/Card';
import { CardContainer, CardWrapper, Header, HeaderContainer, MigrationContainer } from './MigrationElements';

const Migration = () => {
  return (
    <MigrationContainer>
          <HeaderContainer>
              <Header variant="h2">
                  Choose How To Do The Assessment
              </Header>
          </HeaderContainer>
          <CardContainer>
      <CardWrapper>
        {migrationData.map((item, index) => {
          return (
            <Card
              key={index}
              icon={item.icon}
              header={item.header}
              subtitle={item.subtitle}
              //onPress={handleNav(item.link)}
              link={item.link}
            />
          );
        })}
      </CardWrapper>
    </CardContainer>
      </MigrationContainer>
  )
}

export default Migration