import styled from "styled-components";

export const RootWrapper = styled.div`
display: flex;
flex-direction: row;
`

export const BodyWrapper = styled.div`
flex-grow: 1;
margin-right: 3%;
`