export const additionalInfoData = {
  companyName: {
    name: "companyName",
    label: "company name",
    type: "text",
    placeholder: "",
    errorMsg: "company name is required.",
  },
  companySize: {
    name: "companySize",
    label: "company size",
    type: "number",
    invalidMsg: "company size must be greater than 1",
  },
  companyType: {
    name: "companyType",
    label: "company type",
    type: "text",
    placeholder: "eg. Cloud",
    errorMsg: "company type is required.",
  },
  email: {
    name: "email",
    label: "email address",
    type: "email",
    placeholder: "eg. company@gmail.com",
    errorMsg: "Email address is required.",
    invalidMsg: "Your email address is invalid",
  },
  phoneNumber: {
    name: "phoneNumber",
    label: "phone number",
    type: "tel",
  },
};
