import { Box, Card, Grid } from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Button from "../../../components/ui/button";
import Typography from "../../../components/ui/typography";
import EditInput from "./edit input/EditInput";

const Questions = () => {
  const { state } = useLocation();
  const { questions, category } = state; // Read values passed on state
  const [questionArray, setQuestionArray] = useState(questions);
  const handleAddQuestion = () => {
    let tempArray = questionArray;
    tempArray.push({
      category: category,
      name: Math.random().toString(),
      errorMsg: "Field is required",
      label: "",
      type: "text",
      placeholder: "",
    })
    setQuestionArray(
     tempArray 
    );
  };

  const handleDeleteQuestion = () => {
    
  }
  return (
    <Card
      sx={{
        height: "100%",
        marginLeft: "60px",
        marginRight: "60px",
        marginBottom: "30px",
      }}
    >
      <Box m={3} lineHeight={0} display="inline-block">
        <Typography
          component="label"
          variant="subtitle"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {category}
        </Typography>
      </Box>
      <Grid container spacing={3} p={3}>
        {questionArray.map((question, index) => {
          return (
            <Grid key={index} item xs={10} sm={6}>
              <EditInput question={question} />
            </Grid>
          );
        })}
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          p: 2,
        }}
      >
        <Button
          color="dark"
          onClick={handleAddQuestion}
          sx={{ mr: 1 }}
        >
          Add
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          //onClick={handleNext}
          //type="submit"
          variant="gradient"
          color="disraptor"
          //disabled={isSubmitting}
        >
          Save
        </Button>
      </Box>
    </Card>
  );
};

export default Questions;
