import styled from "styled-components";

export const ButtonWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
padding: 10px;
`

export const Footer = styled.div`
display: flex;
flex-direction: row;
padding: 20px;
justify-content: space-between;
`

export const BoxWrapper = styled.div`
margin: auto auto;
max-width: 30vw;
`