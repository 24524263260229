import { React, useState, useContext, useEffect } from "react";
import { LoginWrapper } from "./LoginElements";
import { Auth } from "aws-amplify";
import { Card, Box, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/auth-context";
import LoadingButton from "../../components/ui/loadingButton"
import Message from "../../components/ui/SnackBar/SnackBar";
import Button from "../../components/ui/button";
import { BoxWrapper } from "../signup/SignupElements";
import Input from "../../components/ui/input";
import Typography from "../../components/ui/typography";

const Login = () => {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [step, setStep] = useState(0);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [error, setError] = useState({
    message: "",
    isError: false,
    severity: "",
  });
  const navigate = useNavigate();
  const ctx = useContext(AuthContext);

  const handleNameChange = (event) => {
    setUserName(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleSignUp = () => {
    navigate("/Signup");
  };

  const handleSignIn = async () => {
    try {
      setIsLoading(true);
      await Auth.signIn(username, password);
      let credentials = (await Auth.currentSession())
        .getAccessToken()
        .getJwtToken();
      await Auth.currentAuthenticatedUser()
        .then((user) => {
          console.log(user);
          ctx.setToken(credentials);
          ctx.setUserId(user.attributes.sub);
          ctx.setRole(ctx.user.role);
        })
        .catch((error) => {
          console.log(error);
        });
      ctx.onLogIn();
      navigate("/Answers");
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setError({
        message: error.message,
        isError: true,
        severity: "error",
      });
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setError({
      message: "",
      isError: false,
      severity: "",
    });
  };

  const handleForgotPassword = () => {
    setIsForgotPassword(true);
  };

  const handleSendConfirmationCode = async () => {
    await Auth.forgotPassword(username)
      .then((data) => {
        console.log(data);
        setStep(1);
      })
      .catch((err) => console.log(err));
  };

  const handleConfirmationCodeChange = (event) => {
    setConfirmationCode(event.target.value);
  };

  const handleConfirmationCodeSubmit = async () => {
    await Auth.forgotPasswordSubmit(username, confirmationCode, newPassword)
      .then((data) => {
        console.log(data);
        setStep(0);
        setIsForgotPassword(false);
      })
      .catch((err) => console.log(err));
  };


  return (
    <div>
      <LoginWrapper>
        <BoxWrapper>
          {!isForgotPassword && (
            <Card sx={{ marginTop: "60px", minWidth: "300px" }}>
              <Box p={3} mb={1} textAlign="center">
                <Typography variant="h5" fontWeight="bold">
                  Sign in
                </Typography>
              </Box>
              <Box p={3}>
                <Box component="form" role="form">
                  <Box mb={2}>
                    <Input
                      type="email"
                      placeholder="Email"
                      onChange={handleNameChange}
                      value={username}
                    />
                  </Box>
                  <Box mb={2}>
                    <Input
                      type="password"
                      placeholder="Password"
                      onChange={handlePasswordChange}
                      value={password}
                    />
                  </Box>
                  <Box mt={4} mb={1}>
                    <LoadingButton
                      loading={isLoading}
                      loadingPosition="start"
                      variant="gradient"
                      color="dark"
                      fullWidth
                      onClick={handleSignIn}
                    >
                      sign in
                    </LoadingButton>
                  </Box>
                  <Box position="relative" py={0.25}>
                    <Divider />
                    <Box
                      bgColor="white"
                      position="absolute"
                      top="50%"
                      left="50%"
                      px={1.5}
                      lineHeight={1}
                      sx={{ transform: "translate(-50%, -60%)" }}
                    >
                      <Typography
                        variant="button"
                        fontWeight="medium"
                        color="secondary"
                      >
                        or
                      </Typography>
                    </Box>
                  </Box>
                  <Box mt={1} mb={3}>
                    <Button
                      variant="gradient"
                      color="disraptor"
                      fullWidth
                      onClick={handleSignUp}
                    >
                      sign up
                    </Button>
                  </Box>
                  <Box
                    mt={1}
                    mb={3}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="text"
                      color="dark"
                      onClick={handleForgotPassword}
                    >
                      Forgot Password?
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Card>
          )}
          {isForgotPassword && (
            <Card sx={{ marginTop: "60px" }}>
              <Box p={3} mb={1} textAlign="center">
                <Typography variant="h5" fontWeight="medium">
                  Password Reminder
                </Typography>
              </Box>
              <Box p={3}>
                <Box component="form" role="form">
                  <Box mb={2}>
                    <Input
                      type="text"
                      placeholder="username"
                      onChange={handleNameChange}
                      value={username}
                    />
                  </Box>
                  {step === 1 && (
                    <>
                      <Box mb={2}>
                        <Input
                          type="number"
                          placeholder="Verification Code"
                          onChange={handleConfirmationCodeChange}
                          value={confirmationCode}
                        />
                      </Box>
                      <Box mb={2}>
                        <Input
                          type="password"
                          placeholder="New Password"
                          onChange={handleNewPasswordChange}
                          value={newPassword}
                        />
                      </Box>
                    </>
                  )}
                  <Box mt={4} mb={1}>
                    {step === 0 && (
                      <Button
                        variant="gradient"
                        color="disraptor"
                        fullWidth
                        onClick={handleSendConfirmationCode}
                      >
                        send code
                      </Button>
                    )}
                    {step === 1 && (
                      <Button
                        variant="gradient"
                        color="disraptor"
                        fullWidth
                        onClick={handleConfirmationCodeSubmit}
                      >
                        submit
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Card>
          )}
        </BoxWrapper>
        <Message
          message={error.message}
          severity={error.severity}
          handleClose={handleClose}
          open={error.isError}
        />
      </LoginWrapper>
    </div>
  );
};

export default Login;
