import React from 'react'
import Form from '../Form';

const Security = (props) => {
    const { formField, values, errors, touched } = props.formData;
  const data = formField.security;
  return (
    <Form
      data={data}
      errors={errors}
      values={values}
      touched={touched}
      subtitle="what are your security needs"
      title="Security"
    />
  );
}

export default Security