import React, { useContext } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import AnimatedRoutes from "./routes/AnimatedRoutes";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./assets/theme";
import CssBaseline from "@mui/material/CssBaseline";
import Admin from "./pages/admin/Admin";
import AuthContext from "./context/auth-context";
import { RootWrapper, BodyWrapper } from "./RootWrapper";
import UserRoutes from "./routes/UserRoutes";
import AdminRoutes from "./routes/AdminRoutes";
import { RequireAuth } from "./routes/authGuard/RequireAuth";
import { AwsRum } from 'aws-rum-web';

try {
  const config = {
    sessionSampleRate: 1,
    identityPoolId: "us-east-1:7514edd9-7d8e-4fe6-ba4a-47748b5f3065",
    endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
    telemetries: ["performance","errors","http"],
    allowCookies: true,
    enableXRay: false
  };

  const APPLICATION_ID = 'b78db61a-fe3c-44bf-b9e3-ab007dc11563';
  const APPLICATION_VERSION = '1.0.0';
  const APPLICATION_REGION = 'us-east-1';

  const awsRum = new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  );
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}

function App() {
  const ctx = useContext(AuthContext);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <RootWrapper>
          <BodyWrapper>
            <AnimatedRoutes />
          </BodyWrapper>
        </RootWrapper>
      </Router>
    </ThemeProvider>
  );
}

export default App;
