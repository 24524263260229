export const migrationData = [
  {
    icon: require("../../assets/images/agentless.svg").default,
    header: "Agentless",
    subtitle: "Gather data automatically by installing an agent",
    link: "/Migration/Agentless/TermsAndCo",
  },
  {
    icon: require("../../assets/images/agent.svg").default,
    header: "Agent",
    subtitle: "Answer a set of questions to give an idea of your systems",
    link: "/Migration/Agent",
  },
];
