import React from "react";
import { Link } from "react-router-dom";
import { CardElement, CardH2, CardIcon, CardP, CardLink } from "./CardElements";

const Card = ({ icon, header, subtitle, link }) => {
  return (
    <CardLink to={link}>
    <CardElement>
      <CardIcon src={icon} />
      <CardH2 variant="h3" fontWeight="medium">{header}</CardH2>
      <CardP variant="subtitle2">{subtitle}</CardP>
    </CardElement>
    </CardLink>
  );
};

export default Card;
