import { Box, Card, Grid } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import Typography from "../../../components/ui/typography";

const CustomerDetails = () => {
  const { state } = useLocation();
  const { companyInfo } = state; // Read values passed on state
  console.log(companyInfo);
  return (
    <Card
      sx={{
        height: "100%",
        marginLeft: "60px",
        marginRight: "60px",
      }}
    >
      <Grid container spacing={3} p={3}>
        {companyInfo.Answers.map((answer, index) => {
          return (
            <Grid key={index} item xs={12} sm={6}>
              <Box mb={1.5}>
                <Box mb={1} lineHeight={0} display="inline-block">
                  <Typography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {Object.keys(answer)[0]}
                  </Typography>
                </Box>
                <Typography variant="subtitle2" color="text">
                  {Object.values(answer)[0]}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Card>
  );
};

export default CustomerDetails;
