import React, { useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import SideBar from "../../components/sidebar/SideBar";
import { AdminContainer, BodyContainer, SidebarContainer } from "./AdminElements";

const Admin = (props) => {
  const [menuClick, setMenuClick] = useState(false);

  const handleMenuClick = () => {
    setMenuClick(!menuClick);
  };

  const handleMenuHover = () => {
    setMenuClick(true);
  };

  const handleMenuLeave = () => {
    setMenuClick(false);
  };
  return (
    <AdminContainer>
        <SidebarContainer>
          <SideBar
        menuClick={menuClick}
        onMenuHover={handleMenuHover}
        onMenuLeave={handleMenuLeave}
        onMenuClick={handleMenuClick}
      />  
        </SidebarContainer>
      
      <BodyContainer>
          <Navbar onMenuClick={handleMenuClick} menuClick={menuClick}/>
          {props.children}</BodyContainer>
    </AdminContainer>
  );
};

export default Admin;
