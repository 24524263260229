import React, {useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { QuestionsAPI } from '../../../api/questions/QuestionsAPI';
import { useNavigate } from "react-router-dom";

export default function ManageQuestions() {
    const navigate = useNavigate();
    const [rows, setRows] = useState([]);
    useEffect(() => {
      QuestionsAPI.get().then((response) => {
        setRows(response);
        
      });}, []);
  return (
    <TableContainer component={Paper} sx={{width: '60%'}}>
      <Table  aria-label="simple table">
        <TableHead sx={{width: '100%'}}>
          <TableRow sx={{width: '100%'}}>
            <TableCell align='center'>Categories</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(rows).map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              onClick={() =>
                navigate("/Settings/ManageQuestions/Questions", {
                  state: { questions: Object.values(rows[row]), category: row },
                })
              }
            >
              <TableCell align='center' component="th" scope="row">
                {row}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
