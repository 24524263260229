import FeedbackIcon from '@mui/icons-material/Feedback';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import SettingsIcon from '@mui/icons-material/Settings';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

export const menuData = [
    {
        text: "Answers",
        icon: <FeedbackIcon/>,
        dropdown: false,
        sublist: [{
            name: "item 1",
            link: "/item"
        }],
        link: "/Answers"
    },
    {
        text: "Settings",
        icon: <SettingsIcon/>,
        dropdown: true,
        sublist: [{
            name: "Personal Info",
            link: "/Settings/PersonalInfo",
            rbac: false
        },
        {
            name: "Manage Questions",
            link: "/Settings/ManageQuestions",
            rbac: true
        }],
        link: "/Settings"
    },
]