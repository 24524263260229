import React, { useEffect, useState } from "react";
import axios from "axios";
import { Auth } from "aws-amplify";

const AuthContext = React.createContext({
  isLoggedin: false,
  user: {},
  role: "",
  userId: "",
  token: "",
});

export const AuthContextProvider = (props) => {
  const [isLoggedin, setIsLoggedIn] = useState(true);
  const [role, setRoleState] = useState("admin");
  const [userId, setUserId] = useState("");
  const [token, setToken] = useState("");
  const [user, setUserState] = useState({
    email: "",
    role: "",
  });
  useEffect(() => {
    async function fetchAuthData() {
      const storedUserLoggedInInformation =
        sessionStorage.getItem("isLoggedIn");
      if (storedUserLoggedInInformation === "1") {
        setIsLoggedIn(true);
        let credentials = (await Auth.currentSession())
          .getAccessToken()
          .getJwtToken();
        setToken(credentials);
        await Auth.currentAuthenticatedUser().then((authUser) => {
          setCognitoUserId(authUser.attributes.sub);
          setRoleState(authUser.attributes["custom:Role"]);
        });
      }
    }
    fetchAuthData();
  }, [token, userId, role]);

  const handleLogIn = () => {
    setIsLoggedIn(true);
    sessionStorage.setItem("isLoggedIn", "1");
  };

  const handleLogOut = () => {
    setIsLoggedIn(false);
    sessionStorage.removeItem("isLoggedIn");
  };

  const setUserRole = (userRole) => {
    setRoleState(userRole);
  };

  const setCognitoUserId = (userid) => {
    setUserId(userid);
  };

  const setUserToken = (tkn) => {
    setToken(tkn);
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedin: isLoggedin,
        onLogOut: handleLogOut,
        onLogIn: handleLogIn,
        setRole: setUserRole,
        setUserId: setCognitoUserId,
        setLogIn: handleLogOut,
        setToken: setUserToken,
        role: role,
        user: user,
        userId: userId,
        token: token,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
