import React, { useContext } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Agentless from "../pages/agentless/Agentless";
import Agent from "../pages/agent/Agent";
import Hero from "../pages/hero/Hero";
import Migration from "../pages/migration/Migration";
import Modernization from "../pages/modernization/Modernization";
import TermsAndCon from "../pages/agentless/form/TermsAndCo.js/TermsAndCo";
import Admin from "../pages/admin/Admin";
import Answers from "../pages/answers/Answers";
import ManageQuestions from "../pages/admin/manageQuestions/ManageQuestions";
import CustomerDetails from "../pages/admin/customerDetails/CustomerDetails";
import Questions from "../pages/admin/questions/Questions";
import Login from "../pages/login/Login";
import Signup from "../pages/signup/Signup";
import AuthContext from "../context/auth-context";
import { RequireAuth } from "./authGuard/RequireAuth";

const AnimatedRoutes = () => {
  const location = useLocation();
  const ctx = useContext(AuthContext);
  return (
    <Routes location={location} key={location.pathname}>
      {/* <Route path="/" element={<Hero/>}/> */}
      <Route path="/" element={<Agentless />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/Migration/Agent" element={<Agent />} />
      <Route path="/Migration/Agentless" element={<Agentless />} />
      <Route path="/Migration" element={<Migration />} />
      <Route path="/Modernization" element={<Modernization />} />
      <Route path="/Migration/Agentless/TermsAndCo" element={<TermsAndCon />} />
      <Route
        path="/Settings/ManageQuestions"
        element={
          <RequireAuth>
            <Admin>
              <ManageQuestions />
            </Admin>
          </RequireAuth>
        }
      />
      <Route
        path="/Answers"
        element={
          <RequireAuth>
            <Admin>
              <Answers />
            </Admin>
          </RequireAuth>
        }
      />
      <Route
        path="/Settings/ManageQuestions/Questions"
        element={
          <RequireAuth>
            <Admin>
              <Questions />
            </Admin>
          </RequireAuth>
        }
      />
      <Route
        path="/Answers/Customer Answers"
        element={
          <RequireAuth>
            <Admin>
              <CustomerDetails />
            </Admin>
          </RequireAuth>
        }
      />
    </Routes>
  );
};

export default AnimatedRoutes;
