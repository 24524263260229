import * as Yup from "yup";
import checkout from "./form";

let errorArray = [];

Object.keys(checkout.formField).map(function (key, index) {
    let errors = {};
  Object.keys(checkout.formField[key]).forEach(function (question, index) {
    if (checkout.formField[key][question].type == "email") {
      errors = {
        ...errors,
        [checkout.formField[key][question].name]: Yup.string()
          .required(checkout.formField[key][question].errorMsg)
          .email(checkout.formField[key][question].invalidMsg),
      };
    } else {
      errors = {
        ...errors,
        [checkout.formField[key][question].name]: Yup.string().required(
          checkout.formField[key][question].errorMsg
        ),
      };
    }
  });

  errorArray.push(errors);
});

const validations = errorArray.map((error, index) => {
    return Yup.object().shape(error)
})

export default validations;
