import React from 'react'
import { Box, Grid } from "@mui/material";
import FormField from './FormField';
import Typography from '../../../components/ui/typography';

const Form = ({data, title, subtitle, values, errors, touched}) => {
  return (
    <Box>
      <Box lineHeight={0} sx={{ display: "flex", flexDirection: "column" }}>
        <Typography
          variant="h5"
          fontWeight="bold"
          color="disraptor"
          textGradient
        >
          {title}
        </Typography>
        <Typography variant="button" fontWeight="regular" color="text">
          {subtitle}
        </Typography>
      </Box>
      <Box mt={1.625}>
        <Grid container spacing={3}>
          {Object.keys(data).map((question, index) => {
            return (
              <Grid key={index} item xs={12} sm={6}>
                <FormField
                  type={data[question].type}
                  label={data[question].label}
                  name={data[question].name}
                  value={values[data[question].name]}
                  placeholder={data[question].placeholder}
                  error={
                    errors[data[question].name] &&
                    touched[data[question].name]
                  }
                  success={
                    values[data[question].name].length > 0 &&
                    !errors[data[question].name]
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  )
}

export default Form