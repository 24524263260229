import React from 'react'
import Form from '../Form';

const Refactoring = (props) => {
    const { formField, values, errors, touched } = props.formData;
    const data = formField.refactoring;
  return (
    <Form
      data={data}
      errors={errors}
      values={values}
      touched={touched}
      subtitle="how much refactoring is needed"
      title="refactoring"
    />
  )
}

export default Refactoring