import { Box, Divider, Card } from "@mui/material";
import { React, useContext, useState } from "react";
import { BoxWrapper } from "./SignupElements";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/auth-context";
import Input from "../../components/ui/input";
import Button from "../../components/ui/button";
import Typography from "../../components/ui/typography";

const Signup = () => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [step, setStep] = useState(0);
  const [authenticationCode, setAuthenticationCode] = useState("");
  const navigate = useNavigate();
  const ctx = useContext(AuthContext);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleVerificationCodeChange = (event) => {
    setAuthenticationCode(event.target.value);
  };

  const handleSignUp = async () => {
    try {
      const { user } = await Auth.signUp({
        username: email,
        password: password,
        attributes: {email, "custom:Role": "Admin" },
      });
      console.log(user);
      setStep(1);
    } catch (error) {
      console.log("error signing up:", error);
    }
  };

  const confirmSignUp = async () => {
    try {
      await Auth.confirmSignUp(email, authenticationCode)
        .then((user) => {
          console.log("verified: ", user);
        })
        .catch((err) => {
          console.log(err);
        });
      await Auth.signIn(email, password)
        .then((user) => {
          ctx.setUserId(user.attributes.sub);
          ctx.setRole(user.attributes["custom:Role"]);
          navigate("/");
          ctx.onLogIn();
        })
        .catch((err) => {
          console.log(err);
        });
      await Auth.currentUserInfo()
        .then((user) => {
          ctx.user = user;
          console.log("current user, ", user);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log("error confirming sign up: ", error);
    }
  };

  const handleSignIn = () => {
    navigate("/Login");
  };
  return (
    <BoxWrapper>
      {step === 0 && (
        <Card sx={{ margin: "auto" }}>
          <Box p={3} textAlign="center">
            <Typography variant="h5" fontWeight="bold">
              Sign Up
            </Typography>
          </Box>
          <Box p={3}>
            <Box component="form" role="form">
              <Box mb={2}>
                <Input
                  type="email"
                  placeholder="Email"
                  onChange={handleEmailChange}
                  value={email}
                />
              </Box>
              <Box mb={2}>
                <Input
                  type="password"
                  placeholder="Password"
                  onChange={handlePasswordChange}
                  value={password}
                />
              </Box>
              <Box mt={4} mb={1}>
                <Button
                  variant="gradient"
                  color="disraptor"
                  fullWidth
                  onClick={handleSignUp}
                >
                  sign up
                </Button>
              </Box>
              <Box position="relative" py={0.25}>
                <Divider />
                <Box
                  bgColor="white"
                  position="absolute"
                  top="50%"
                  left="50%"
                  px={1.5}
                  lineHeight={1}
                  sx={{ transform: "translate(-50%, -60%)" }}
                >
                  <Typography
                    variant="button"
                    fontWeight="medium"
                    color="secondary"
                  >
                    or
                  </Typography>
                </Box>
              </Box>
              <Box mt={1} mb={3}>
                <Button
                  variant="gradient"
                  color="dark"
                  fullWidth
                  onClick={handleSignIn}
                >
                  sign in
                </Button>
              </Box>
            </Box>
          </Box>
        </Card>
      )}
      {step === 1 && (
        <Card>
          <Box p={3} mb={1} textAlign="center">
            <Typography variant="h5" fontWeight="medium">
              Confirm Sign Up
            </Typography>
          </Box>
          <Box p={3}>
            <Box component="form" role="form">
              <Box mb={2}>
                <Input
                  type="email"
                  placeholder="Email"
                  onChange={handleEmailChange}
                  value={email}
                />
              </Box>
              <Box mb={2}>
                <Input
                  type="number"
                  placeholder="Verification Code"
                  onChange={handleVerificationCodeChange}
                  value={authenticationCode}
                />
              </Box>
              <Box mt={4} mb={1}>
                <Button
                  variant="gradient"
                  color="disraptor"
                  fullWidth
                  onClick={confirmSignUp}
                >
                  confirm sign up
                </Button>
              </Box>
            </Box>
          </Box>
        </Card>
      )}
    </BoxWrapper>
  );
};

export default Signup;
