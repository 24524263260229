import React from "react";
import Form from "../Form";

const Performance = (props) => {
  const { formField, values, errors, touched } = props.formData;
  const data = formField.performance;
  return (
    <Form
      data={data}
      errors={errors}
      values={values}
      touched={touched}
      subtitle="what is your current performance"
      title="Performance"
    />
  );
};

export default Performance;
