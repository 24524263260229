import {
    Box,
    CardContent,
    Typography,
    Card,
    Checkbox,
    FormControlLabel
  } from "@mui/material";
  import { React, useState } from "react";
import {  useNavigate } from "react-router";
import Button from "../../../../components/ui/button";
import { Footer } from "./TermsAndCoElements";
  
  const TermsAndCon = (props) => {
    const [boxChecked, setBoxChecked] = useState(false);
  
    const handleBoxChecked = (event) => {
      setBoxChecked(event.target.checked);
    };
    const navigate = useNavigate();
  
    return (
      <>
        <Box  py={3} pl={20} pr={20} width={"80%"} margin={"auto"}>
          <Card sx={{ padding: "50px" }}>
            <Card>
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  maxHeight: "80vh",
                  overflow: "auto",
                }}
              >
                <Typography variant="body2">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                  hendrerit tellus vel ornare maximus. Ut massa sapien, lobortis a
                  pretium in, faucibus et eros. Duis dapibus massa odio. Sed
                  posuere facilisis finibus. Quisque interdum ipsum et convallis
                  vestibulum. Vestibulum dignissim mi enim, in volutpat urna
                  pellentesque non. Morbi pellentesque ex eu pulvinar auctor.
                  Suspendisse lacinia semper posuere. Vestibulum ante ipsum primis
                  in faucibus orci luctus et ultrices posuere cubilia curae;
                  Integer feugiat molestie magna. Integer at tortor et urna
                  pharetra tristique. Suspendisse ullamcorper commodo ante. Donec
                  fermentum ligula ut pellentesque tempor. Proin id orci vel nulla
                  aliquam tempus. Aenean at est metus. Sed et tellus id augue
                  interdum ullamcorper. Etiam eu diam nisi. Suspendisse id ipsum
                  sed enim tincidunt mollis. Nulla posuere augue vel lobortis
                  lacinia. Fusce nec nulla posuere, hendrerit erat in, porta
                  tellus. Praesent a congue nibh, nec efficitur mi. Sed ligula
                  diam, eleifend sed egestas quis, pellentesque et augue. Nulla ac
                  ante lacus. Orci varius natoque penatibus et magnis dis
                  parturient montes, nascetur ridiculus mus. Morbi tristique
                  condimentum purus, vel tristique ante. Nulla et mauris eget
                  justo tempus pretium eget convallis turpis. Curabitur bibendum
                  dapibus arcu, eget vulputate diam porta vitae. Donec ut elit
                  feugiat, tristique tellus vel, molestie urna. Curabitur interdum
                  libero vel cursus vehicula. Phasellus magna neque, hendrerit vel
                  ultricies vel, tempor sed urna.
                </Typography>
              </CardContent>
            </Card>
            <Footer>
              <FormControlLabel
                label="I Agree To The Terms And Conditions"
                control={
                  <Checkbox
                    aria-label="Terms and Conditions"
                    onChange={handleBoxChecked}
                  />
                }
              />
                <Button
                  variant="gradient"
                  color="disraptor"
                  disabled={!boxChecked}
                  onClick={() => {navigate("/Migration/Agentless")}}
                >
                  Next
                </Button>
            </Footer>
          </Card>
        </Box>
      </>
    );
  };
  
  export default TermsAndCon;
  