import styled from 'styled-components'
import Typography from '../../components/ui/typography'

export const HeroContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
height: 80vh;

`

export const HeroWrapper = styled.div`

`

export const CardContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin-top: 80px;
`

export const CardWrapper = styled.div`
max-width: 1000px;
margin: 0 auto;
display: grid;
grid-template-columns: 1fr 1fr;
align-items: center;
grid-gap: 136px;
padding: 0 50px;

@media screen and (max-width: 1000px){
    grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 768px){
    grid-template-columns: 1fr;
    padding: 0 20px;
}
`
export const HeaderWrapper = styled.div`

`

export const HeaderContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
`
export const Header = styled(Typography)`

`
