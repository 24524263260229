import React from "react";
import Form from "../Form";

const Discovery = (props) => {
  const { formField, values, errors, touched } = props.formData;
  const data = formField.discovery;

  return (
    <Form
      data={data}
      errors={errors}
      values={values}
      touched={touched}
      subtitle="Tell us about your infrastructure"
      title="Discovery of Infrastructure"
    />
  );
};

export default Discovery;
