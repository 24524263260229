import styled from 'styled-components'
import {Link as LinkR} from 'react-router-dom';
import Typography from '../typography';

export const CardElement = styled.div`
background: #fff;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
border-radius: 10px;
max-height: 340px;
padding: 30px;
box-shadow: 0 1px 3px rgba(0,0,0,0.2);
transition: all 0.2s ease-in-out;

&:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}
`

export const CardIcon = styled.img`
height: 160px;
width: 160px;
margin-bottom: 10px;
`

export const CardH2 = styled(Typography)`
font-size: 1rem;
margin-bottom: 10px;
color: black;
`

export const CardP = styled(Typography)`
font-size: 1rem;
text-align: center;
color: black;
`

export const CardLink = styled(LinkR)`
text-decoration: none;
`

