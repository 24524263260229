import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import { styled } from '@mui/material/styles';
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CustomersAPI } from "../../api/customers/CustomersAPI";
import { useNavigate, Navigate } from "react-router-dom";

export default function Answers() {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  useEffect(() => {
    CustomersAPI.get().then((response) => {
      setRows(response);
    });
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <TableContainer component={Paper} sx={{width: '60%'}}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Company Name</StyledTableCell>
            <StyledTableCell>Company Type</StyledTableCell>
            <StyledTableCell>Company Size</StyledTableCell>
            <StyledTableCell>Email</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow
              key={row["Company Name"]}
              onClick={() =>
                navigate("/Answers/Customer Answers", {
                  state: { companyInfo: row },
                })
              }
            >
              <StyledTableCell>{row["Company Name"]}</StyledTableCell>
              <StyledTableCell>{row["Company Type"]}</StyledTableCell>
              <StyledTableCell>{row["Company Size"]}</StyledTableCell>
              <StyledTableCell>{row["Email"]}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

